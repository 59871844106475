<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box
        :back="true"
        class="px-4"
        :back-component-name="parentRouteName || null"
      >
        <template v-slot:title>
          <span>{{ $t("createCompany.create_company") }}</span>
        </template>
        <template v-slot:preview>
          <div class="row px-4 py-4">
            <div class="col-8">
              <div class="row">
                <div class="col-12">
                  <text-input
                    :is-inline="true"
                    :helper-text="$t('createCompany.please_enter_company_name')"
                    :placeholder="$t('createCompany.please_enter_company_name')"
                    :title="$t('createCompany.company_name')"
                    :required="true"
                    :model.sync="companyName"
                    :is-valid="companyName !== null && companyName.length !== 0"
                  ></text-input>
                  <textarea-input
                    :is-inline="true"
                    :helper-text="
                      $t('createCompany.please_enter_company_address')
                    "
                    :placeholder="
                      $t('createCompany.please_enter_company_address')
                    "
                    :required="false"
                    :model.sync="address"
                    :title="$t('createCompany.company_address')"
                    :is-valid="
                      address != null && address.length !== 0 ? true : null
                    "
                  ></textarea-input>
                  <text-input
                    :is-inline="true"
                    :helper-text="
                      $t('createCompany.please_enter_tax_administration')
                    "
                    :placeholder="
                      $t('createCompany.please_enter_tax_administration')
                    "
                    :title="$t('createCompany.tax_administration')"
                    :required="false"
                    :model.sync="taxAdministration"
                    :is-valid="
                      taxAdministration != null &&
                      taxAdministration.length !== 0
                        ? true
                        : null
                    "
                  ></text-input>
                  <text-input
                    :is-inline="true"
                    :helper-text="$t('createCompany.please_enter_tax_number')"
                    :placeholder="$t('createCompany.please_enter_tax_number')"
                    :title="$t('createCompany.tax_number')"
                    :required="false"
                    :model.sync="taxNumber"
                    :is-valid="
                      taxNumber != null && taxNumber.length !== 0 ? true : null
                    "
                  ></text-input>
                </div>
              </div>
            </div>
            <div class="col-4 text-center">
              <img
                v-if="avatar !== null"
                :src="avatarBlob"
                alt=""
                :class="{ 'w-100px h-100px rounded-lg mb-5': avatar !== null }"
              />
              <span
                v-else
                v-html="
                  getIconByKey('icons.meeting.company-logo', {
                    class: 'w-75px h-75px d-inline-block',
                  })
                "
              >
              </span>
              <file-input
                @fileChange="onProfileImageChange"
                :model.sync="avatar"
                :is-inline="false"
                :title="$t('createCompany.company_profile_image')"
                :helper-text="
                  $t('createCompany.please_select_company_profile_image')
                "
                :required="true"
                :error="
                  avatar == null && avatarBlob != null
                    ? $t('createCompany.company_profile_image_cant_be_empty')
                    : null
                "
              ></file-input>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-sm btn-outline-danger mr-3"
              @click="$router.go(-1)"
            >
              {{ $t("general.back") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              :disabled="!isFormValid"
              @click="submitForm"
            >
              {{ $t("general.save") }}
            </button>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import TextInput from "@/assets/components/inputs/TextInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import FileInput from "@/assets/components/inputs/FileInput";
import { CREATE_ITEM } from "@/core/services/store/REST.module";

export default {
  name: "GeneralCompanyCreate",
  components: {
    FileInput,
    TextareaInput,
    TextInput,
    DashboardBox,
  },
  computed: {
    ...mapGetters({}),
    isFormValid() {
      return this.companyName !== null;
    },
  },
  data() {
    return {
      companyName: null,
      address: null,
      taxAdministration: null,
      taxNumber: null,
      avatar: null,
      avatarBlob: null,

      parentRouteName: null,
      parentName: null,
    };
  },
  methods: {
    ...mapMutations({}),
    onProfileImageChange(payload) {
      this.avatarBlob = payload.blob;
    },
    submitForm() {
      if (!this.isFormValid) {
        this.sweetAlertError(
          this.$t("createCompany.please_fill_required_fields")
        );
        return;
      }
      let self = this;
      let contents = new FormData();
      contents.append("name", this.companyName);
      contents.append("address", this.address);
      contents.append("tax_administration", this.taxAdministration);
      contents.append("tax_number", this.taxNumber);

      if (this.avatar) {
        contents.append("avatar", this.avatar);
      }

      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/companies",
          contents: contents,
          acceptPromise: true,
        })
        .then((result) => {
          self
            .sweetAlertSuccess(self.$t("general.successfully_created"))
            .then((_) => {
              self.$router.go(-1);
            });
        });
    },
  },
  mounted() {
    this.parentName = this.$route.params.parentName;
    this.parentRouteName = this.$route.params.parentRouteName;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t(this.parentName || "createCompany.parent"),
        route: this.parentRouteName || "dashboard",
      },
      { title: this.$t("createCompany.create_company") },
    ]);
  },
};
</script>

<style scoped lang="scss">
.image-section {
  img {
    text-align: center;
  }
}
</style>
